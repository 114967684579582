import Component from '@glimmer/component';

import { action } from '@ember/object';
interface BadgeInteractiveSignature {
  Args: {
    label: string;
    icon?: string;
    isSmall?: boolean;
    onRemove?: Function;

    // Uses neon badge which supports background color
    color?: string;

    disableBubbling?: boolean;
    preventDefault?: boolean;

    // Trigger
    onClick: Function;
    disabled?: boolean;
    onSuccess?: Function;
    onError?: Function;
  };
  Element: HTMLElement;
}

export default class BadgeInteractiveComponent extends Component<BadgeInteractiveSignature> {
  @action
  onClick(event: Event) {
    if (this.args.preventDefault) {
      event.preventDefault();
    }
    if (this.args.disableBubbling) {
      event.stopPropagation();
    }
    this.args.onClick?.(event);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Badge::Interactive': typeof BadgeInteractiveComponent;
    'badge/interactive': typeof BadgeInteractiveComponent;
  }
}
