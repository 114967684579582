import Component from '@glimmer/component';

import type { FormYieldedArgs } from '../form.ts';

interface FormCtaSignature {
  Args: {
    f?: FormYieldedArgs;
    isDisabled?: boolean;

    type?: string;
    onClick?: Function;
    testSelector?: string;
    classes?: string;
    narrowStyle?: boolean;
    isBusy?: boolean;
    small?: boolean;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLButtonElement;
}

export default class FormCtaComponent extends Component<FormCtaSignature> {
  get showDisabled() {
    if (this.args.f) {
      const { showCtaDisabled } = this.args.f.state;

      return showCtaDisabled;
    }
    return this.args.isDisabled ?? false;
  }

  get isSubmittable() {
    return this.args.f.state.isSubmittable;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Form::Cta': typeof FormCtaComponent;
  }
}
