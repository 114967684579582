import Component from '@glimmer/component';

interface UiAttachmentTargetSignature {
  Args: {
    eventType?: 'click' | 'hover';
    guid: string;
    classes?: string;
    testSelector?: string;

    toggleTooltip: (event: MouseEvent) => void;
    showTooltip?: (event: MouseEvent) => void;
    hideTooltip?: (event: MouseEvent) => void;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

export default class UiAttachmentTargetComponent extends Component<UiAttachmentTargetSignature> {
  get eventType(): string {
    return this.args.eventType ?? 'hover';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'UiAttachment::Target': typeof UiAttachmentTargetComponent;
    'ui-attachment/target': typeof UiAttachmentTargetComponent;
  }
}
