import Component from '@glimmer/component';

import { action } from '@ember/object';

interface TetheredPopupSignature {
  Args: {
    isOpen: boolean;
    onBackgroundClicked?: Function;
    backgroundClickEnabled?: boolean;
    capture?: boolean;

    target?: string;
    targetAttachment?: string;
    attachment?: string;
    tetherClasses?: string;
    contentClasses?: string;
    zIndex?: string;
    exceptSelector?: string;
    classes?: string;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}
export default class TetheredPopupComponent extends Component<TetheredPopupSignature> {
  get backgroundClickEnabled() {
    return this.args.backgroundClickEnabled ?? true;
  }

  @action
  backgroundClicked() {
    if (this.args.isOpen && this.backgroundClickEnabled) {
      return this.args.onBackgroundClicked?.();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    TetheredPopup: typeof TetheredPopupComponent;
    'tethered-popup': typeof TetheredPopupComponent;
  }
}
