import Component from '@glimmer/component';

import { inject as service } from '@ember/service';

import type ConfigService from '../../services/config.ts';

interface FormRowSignature {
  Args: {
    marginBottom?: string;
    negativeMargin?: string;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

export default class FormRowComponent extends Component<FormRowSignature> {
  @service config!: ConfigService;

  get marginBottom() {
    return this.args.marginBottom ? `mb-${this.args.marginBottom}` : 'mb-6';
  }

  get negativeMargin() {
    const margin =
      this.args.negativeMargin ??
      this.config.getValue('../...components.form.input.negativeMargin');
    return margin ? `-mx-${margin}` : '-mx-2';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Form::Row': typeof FormRowComponent;
  }
}
