import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';

interface UiAttachmentSignature {
  Args: {
    displayTooltip?: boolean;
    isEnabled?: boolean;
    anchor?: string;

    onShowTooltip?: Function;
    onHideTooltip?: Function;

    testSelector?: string;
    classes?: string;
  };
  Blocks: {
    default: [
      {
        ui: {
          target: any;
          tooltip: any;
          popover: any;
        };
        state: {
          targetId: string;
          isOpen: boolean;
        };
        actions: {
          showTooltip: () => void;
          hideTooltip: () => void;
        };
      }
    ];
  };
}

export default class UiAttachment extends Component<UiAttachmentSignature> {
  @tracked displayTooltip: boolean;

  constructor(owner: UiAttachment, args: UiAttachmentSignature['Args']) {
    super(owner, args);
    this.displayTooltip = args.displayTooltip ?? false;
  }

  get isEnabled(): boolean {
    return 'isEnabled' in this.args ? this.args.isEnabled : true;
  }

  get anchor(): string {
    return this.args.anchor ?? 'top center';
  }

  get guid() {
    return guidFor(this);
  }

  @action
  showTooltip() {
    if (!this.isEnabled) return;
    this.displayTooltip = true;
    this.args.onShowTooltip?.();
  }

  @action
  hideTooltip() {
    if (!this.isEnabled) return;
    this.displayTooltip = false;
    this.args.onHideTooltip?.();
  }

  @action
  toggleTooltip(event: Event) {
    event.stopPropagation(); // Prevent parent clicks

    if (this.displayTooltip) {
      return this.hideTooltip();
    }

    return this.showTooltip();
  }
}
