import Service from '@ember/service';
import { inject as service } from '@ember/service';

import type BaseSettingsService from './settings-base.ts';

type Currency = 'EUR' | 'GBP';

const CurrencyName: { [code in Currency]: string } = {
  EUR: 'Euro',
  GBP: 'Pound'
};
const CurrencySymbol: { [code in Currency]: string } = {
  EUR: '€',
  GBP: '£'
};

export default class CurrencyService extends Service {
  @service() settings!: BaseSettingsService<{ value: any }>;

  get symbol(): string {
    const currency = this.settings.getSetting<Currency>('currency');
    return CurrencySymbol[currency] || '?';
  }

  get name(): string {
    const currency = this.settings.getSetting<Currency>('currency');
    return CurrencyName[currency] || '?';
  }
}
