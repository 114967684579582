import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { action } from '@ember/object';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import type RouterService from '@ember/routing/router';
import { inject as service } from '@ember/service';
import or from 'ember-truth-helpers/helpers/or';
import htmlSafeSanitized from '../../helpers/html-safe-sanitized.ts';
import type { NotificationType, Notification } from '../../services/notifications.ts';
import Button from '../button.gts';
import Icon from '../icon.gts';
import Loading from '../loading.gts';
interface NotificationBaseSignature {
    Args: {
        notification: Notification;
        isVisible: boolean;
        dismissNotification: () => void;
        openLink: (link: HTMLAnchorElement, e: MouseEvent) => boolean;
    };
}
const COLORS: {
    bgColor: {
        [key in NotificationType & 'default']: string;
    };
    textColor: {
        [key in NotificationType & 'default']: string;
    };
    hoverTextColor: {
        [key in NotificationType & 'default']: string;
    };
} = {
    bgColor: {
        success: 'bg-green-500',
        warning: 'bg-orange-500',
        error: 'bg-red-500',
        neutral: 'bg-blue-500',
        default: 'bg-teal-500',
        intermediate: 'bg-black'
    },
    textColor: {
        success: 'text-green-500',
        warning: 'text-orange-500',
        error: 'text-red-500',
        neutral: 'text-blue-500',
        default: 'text-teal-500',
        intermediate: 'text-black'
    },
    hoverTextColor: {
        success: 'hover:text-green-500',
        warning: 'hover:text-orange-500',
        error: 'hover:text-red-500',
        neutral: 'hover:text-blue-500',
        default: 'hover:text-teal-500',
        intermediate: 'hover:text-black'
    }
};
const ICONS: {
    [key in NotificationType & 'default']: string;
} = {
    success: 'success-filled',
    warning: 'alert-filled',
    error: 'error-filled',
    neutral: 'alert-filled',
    default: '',
    intermediate: ''
};
export default class NotificationBase<TSignature> extends Component<NotificationBaseSignature & TSignature> {
    @service
    router: RouterService;
    type = this.args.notification.type || 'default';
    isIntermediate = this.type === 'intermediate';
    bgColor = COLORS.bgColor[this.type];
    textColor = COLORS.textColor[this.type];
    hoverTextColor = COLORS.hoverTextColor[this.type];
    icon = ICONS[this.type];
    large = false;
    @action
    checkLinks(element1: HTMLElement) {
        const icon1 = element1.querySelector('[data-notification-link-icon]');
        element1.querySelectorAll('a.notification-link').forEach((link1: HTMLAnchorElement)=>{
            link1.append(icon1!.firstElementChild!.cloneNode(true));
            link1.addEventListener('click', (e1)=>this.args.openLink(link1, e1));
        });
    }
    static{
        template(`
    <div
      data-test-notification={{or @notification.testSelector true}}
      data-test-notification-type={{@notification.type}}
      class="mb-3 max-w-md
        {{if @isVisible "animation-flash-show" "animation-flash-hide"}}
      "
      {{didInsert this.checkLinks}}
    >
      <div class="hidden" data-notification-link-icon>
        <Icon @name="popup" @w="w-3" @classes="ml-2" />
      </div>

      <div class="cursor-pointer py-3 bg-black/[.95]
        text-white rounded-sm shadow flex items-start
        {{if @isVisible "animation-flash-in" "animation-flash-out"}}
        "
      >
        {{#if this.icon}}
          <div class="px-3 {{this.textColor}}">
            <Icon
              @name={{this.icon}}
              @w={{if this.large "w-5 mt-0.5" "w-6"}}
            />
          </div>
        {{/if}}

        {{#if this.isIntermediate}}
          <div class="relative px-3">
            <Loading @type="dots" />
          </div>
        {{/if}}

        <div data-test-notification-message class="flex-grow pr-2 {{if this.large "" "mt-1"}}">
          {{#if @notification.header}}
            <div class="font-medium" data-test-notification-header>
              {{@notification.header}}
            </div>
          {{/if}}
          {{#if @notification.message}}
            <div class="flex">
              <div class="flex-grow {{if @notification.header "text-sm mt-3" "font-medium"}}">
                {{htmlSafeSanitized @notification.message}}
              </div>
            </div>
          {{/if}}

          {{#if @notification.list}}
            <ul class="text-sm p-0 {{if @notification.header "mt-3" "mt-1"}}">
              {{#each @notification.list as |listMessage|}}
                <li data-test-notification-list-item class="mb-1">{{htmlSafeSanitized listMessage}}</li>
              {{/each}}
            </ul>
          {{/if}}
        </div>

        {{#unless this.isIntermediate}}
          <Button
            @onClick={{@dismissNotification}}
            class="px-4 text-white {{this.hoverTextColor}} {{if this.large "mt-1"}}"
          >
            <Icon @name="x" @w="w-4" />
          </Button>
        {{/unless}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
