import Component from '@glimmer/component';
import type { WithBoundArgs } from '@glint/template';

import { guidFor } from '@ember/object/internals';

import { consume, provide } from 'ember-provide-consume-context';

import type { FormContext } from '../-control-base.ts';
import type { FormYieldedArgs } from '../../../components/form.ts';
import type { FormTheme } from '../theme.ts';
import type FormBaseValidationErrorComponent from './validation-error.ts';
import type FormBaseWrapperComponent from './wrapper.ts';
interface FormBaseFormFieldSignature {
  Args: {
    f?: FormContext;
    property: string;
    label?: string;
    theme?: FormTheme;
    labelIcon?: string;
    hint?: string;
    isDisabled?: boolean;
    testSelector?: string;
  };
  Blocks: {
    default: [
      {
        ui: {
          wrapper: WithBoundArgs<
            typeof FormBaseWrapperComponent,
            | 'hint'
            | 'displayErrors'
            | 'validationErrors'
            | 'guid'
            | 'label'
            | 'validationError'
            | 'testSelector'
            | 'isDisabled'
            | 'isRequired'
          >;

          validationError: WithBoundArgs<
            typeof FormBaseValidationErrorComponent,
            'testSelector'
          >;
        };
        state: {
          displayErrors: FormBaseFormFieldComponent['displayErrors'];
          validationErrorsForField: FormBaseFormFieldComponent['validationErrorsForField'];
          validationWarningsForField: FormBaseFormFieldComponent['validationWarningsForField'];
          wasInteractedWith: FormBaseFormFieldComponent['wasInteractedWith'];
          guid: FormBaseFormFieldComponent['guid'];
          value: FormBaseFormFieldComponent['value'];
          isRequired: FormBaseFormFieldComponent['isRequired'];
          isInvalid: FormBaseFormFieldComponent['displayErrors'];
        };
        actions: {
          markFormFieldInteractedWith: FormYieldedArgs['actions']['markFormFieldInteractedWith'];
          change: FormBaseFormFieldComponent['change'];
          changeOnInput: FormBaseFormFieldComponent['changeOnInput'];
        };
      }
    ];
  };
}

export default class FormBaseFormFieldComponent extends Component<FormBaseFormFieldSignature> {
  @consume('form') _f: FormBaseFormFieldSignature['Args']['f'];

  @provide('form-field')
  get api() {
    const {
      f: {
        actions: { markFormFieldInteractedWith }
      },
      change,
      changeOnInput
    } = this;

    const {
      displayErrors,
      validationErrorsForField,
      validationWarningsForField,
      wasInteractedWith,
      guid,
      value,
      isRequired,
      displayErrors: isInvalid
    } = this;

    const { property } = this.args;

    return {
      state: {
        displayErrors,
        validationErrorsForField,
        validationWarningsForField,
        wasInteractedWith,
        guid,
        value,
        isRequired,
        isInvalid,
        property
      },
      actions: {
        markFormFieldInteractedWith,
        change,
        changeOnInput
      }
    };
  }

  // fallback to be able to use context or explicit args
  get f() {
    return this.args.f || this._f!;
  }

  get guid() {
    return guidFor(this);
  }

  get value() {
    const { f } = this;
    const { property } = this.args;

    // @ts-ignore
    return f.state.formObject[property];
  }

  get displayHint() {
    return !!this.args.hint;
  }

  get displayErrors() {
    return (
      !this.args.isDisabled &&
      this.validationErrorsForField.length +
        this.validationWarningsForField.length >
        0 &&
      ((this.f.state.displayErrorOnInteraction && this.wasInteractedWith) ||
        this.f.state.userDidTrySubmit)
    );
  }

  get validationErrorsForField() {
    const { property } = this.args;
    const {
      f: {
        state: { validationErrors }
      }
    } = this;

    return validationErrors.filter(({ path }) => path === property);
  }
  get validationWarningsForField() {
    const {
      f: {
        state: { validationWarnings }
      }
    } = this;
    const { property } = this.args;

    return validationWarnings.filter(({ path }) => path === property);
  }

  get isRequired() {
    const {
      f: {
        state: { requiredFormFields }
      }
    } = this;
    const { property } = this.args;

    return requiredFormFields.includes(property);
  }

  get wasInteractedWith() {
    const {
      f: {
        state: { interactedFormFields }
      }
    } = this;
    const { property } = this.args;

    return interactedFormFields.includes(property);
  }

  get change() {
    const { f } = this;
    const { property } = this.args;

    return f.actions.change.bind(null, property);
  }

  get changeOnInput() {
    const { f } = this;
    const { property } = this.args;

    return f.actions.changeOnInput.bind(null, property);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Form::Base::FormField': typeof FormBaseFormFieldComponent;
    'form/base/form-field': typeof FormBaseFormFieldComponent;
  }
}
