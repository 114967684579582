import Component from '@glimmer/component';
import { resolve } from 'rsvp';
import { Machine } from 'xstate';

import { action } from '@ember/object';

import { useMachine } from 'ember-statecharts';

import { matchesState } from '../utils/statecharts.ts';

interface StateSchema {
  states: {
    idle: {};
    disabled: {};
    busy: {};
    success: {};
    error: {};
  };
}

type StatechartEvent =
  | { type: 'CLICK'; event: Event }
  | { type: 'DISABLE' }
  | { type: 'ACTIVATE' }
  | { type: 'RESOLVE'; result: any }
  | { type: 'REJECT'; error: any };

interface TriggerSignature {
  Args: {
    onClick: Function;
    disabled?: boolean;
    onSuccess?: Function;
    onError?: Function;
  };
  Blocks: {
    default: [
      {
        state: {
          isBusy: boolean;
          isDisabled: boolean;
        };
        actions: {
          click: TriggerComponent['handleButtonClick'];
        };
      }
    ];
  };
}

export default class TriggerComponent extends Component<TriggerSignature> {
  constructor(owner: unknown, args: TriggerSignature['Args']) {
    super(owner, args);

    if (this.args.disabled) {
      this.statechart.send('DISABLE');
    } else {
      this.statechart.send('ACTIVATE');
    }
  }

  @matchesState('busy') isBusy!: boolean;

  @matchesState('disabled') isInDisabledState!: boolean;

  get isDisabled() {
    return this.isBusy || this.isInDisabledState;
  }

  statechart = useMachine(this, () => ({
    machine: Machine<{}, StateSchema, StatechartEvent>({
      initial: 'idle',
      states: {
        idle: {
          on: {
            CLICK: 'busy',
            DISABLE: 'disabled'
          }
        },
        disabled: {
          on: {
            ACTIVATE: 'idle'
          }
        },
        busy: {
          entry: ['triggerOnClick'],
          on: {
            RESOLVE: 'success',
            REJECT: 'error',
            DISABLE: 'disabled',
            ACTIVATE: 'idle'
          }
        },
        success: {
          entry: ['handleSuccess'],
          on: {
            ACTIVATE: 'idle'
          }
        },
        error: {
          entry: ['handleError'],
          on: {
            ACTIVATE: 'idle'
          }
        }
      }
    }).withConfig({
      actions: {
        triggerOnClick: (
          _,
          { event }: Extract<StatechartEvent, { type: 'CLICK' }>
        ) => {
          return resolve(event)
            .then(event => this.args.onClick(event))
            .then(() => this.resolve())
            .catch((error: any) => this.reject(error));
        },
        handleSuccess: () => {
          return resolve()
            .then(() => this.args.onSuccess?.())
            .then(() => this.enableButtonAgain());
        },
        handleError: (
          _,
          { error }: Extract<StatechartEvent, { type: 'REJECT' }>
        ) => {
          return resolve()
            .then(() => this.args.onError?.(error))
            .then(() => this.enableButtonAgain());
        }
      }
    })
  }));

  enableButtonAgain(): any {
    return this.statechart.send('ACTIVATE');
  }

  resolve(): any {
    return this.statechart.send('RESOLVE');
  }

  reject(error: any): any {
    return this.statechart.send('REJECT', { error });
  }

  @action
  handleButtonClick(event: Event) {
    if (!this.isDisabled) {
      return this.statechart.send('CLICK', { event });
    }

    return;
  }

  @action
  handleDisabled() {
    if (this.args.disabled) {
      this.statechart.send('DISABLE');
    } else {
      this.statechart.send('ACTIVATE');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Trigger: typeof TriggerComponent;
    trigger: typeof TriggerComponent;
  }
}
