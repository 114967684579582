import Component from '@glimmer/component';

interface UiAttachmentTooltipArrowSignature {
  Args: {
    isLarge: boolean;
    isArrowBefore: boolean;
    axis: string;
    axisPosition: string;
    colorClasses: string;
    testSelector?: string;
  };
  Blocks: {};
}

export default class UiAttachmentTooltipArrowComponent extends Component<UiAttachmentTooltipArrowSignature> {
  get classes() {
    const { isLarge, axis, axisPosition, isArrowBefore } = this.args;
    const classes = [];

    if (isLarge) {
      classes.push('tooltip-arrow-container-large');
    } else {
      classes.push('tooltip-arrow-container');
    }

    if (axis === 'x') {
      classes.push(`tooltip-arrow-v justify-${axisPosition}`);
    }
    if (axis === 'y') {
      classes.push(`tooltip-arrow-h items-${axisPosition}`);
    }

    classes.push(`tooltip-arrow-${isArrowBefore ? 'before' : 'after'}`);

    return classes.join(' ');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'UiAttachment::Tooltip::Arrow': typeof UiAttachmentTooltipArrowComponent;
    'ui-attachment/tooltip/arrow': typeof UiAttachmentTooltipArrowComponent;
  }
}
