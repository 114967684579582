import { template } from "@ember/template-compiler";
import Button from './button.gts';
import Icon from './icon.gts';
interface ChipSignature {
    Args: {
        icon: string;
        label?: string;
        contentClasses?: string;
        onRemove?: Function;
    };
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}
const ChipComponent: TemplateOnlyComponent<ChipSignature> = template(`
  <div
    class="
      text-sm font-medium border
      text-green-500 bg-green-50 border-green-500
      hover:bg-green-200
      active:text-green-600 active:bg-green-300 active:border-green-600
      px-2 h-6 rounded-2xl
      inline-flex flex items-center gap-2"
    ...attributes
  >
    <Icon @name={{@icon}} @w="w-4 min-w-4" />
    <div class={{@contentClasses}}>
      {{#if (has-block)}}
        {{yield}}
      {{else}}
        {{@label}}
      {{/if}}
    </div>

    {{#if @onRemove}}
      <Button @onClick={{@onRemove}} data-test-remove-chip as |c|>
        <c.ui.rightIcon @name="x" @w="w-2" @_classes="mt-px" />
      </Button>
    {{/if}}
  </div>

`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ChipComponent;
