import Component from '@glimmer/component';
import type { WithBoundArgs } from '@glint/template';

import TableReordeableCellComponent from './reordeable-cell.ts';
import type TableRow from './row.ts';
import TableHeader from './th.ts';

interface TableHeadSignature {
  Args: {
    registerHeaderCell: Function;
    unregisterHeaderCell: Function;
    cellComponent?: TableHeader<any>;
    isReordeable?: boolean;
    cellClass?: string;
  };
  Blocks: {
    default: [
      {
        ui: {
          row: WithBoundArgs<
            typeof TableRow,
            'class' | 'collapse' | 'toggleExpand' | 'isReordeable'
          >;
          cell: WithBoundArgs<
            typeof TableHeader,
            'cellClass' | 'registerHeaderCell' | 'unregisterHeaderCell'
          >;
          reordeableCell: typeof TableReordeableCellComponent;
        };
      }
    ];
  };
  Element: HTMLTableSectionElement;
}

export default class TableHeadComponent extends Component<TableHeadSignature> {
  reorderableCellComponent = TableReordeableCellComponent;

  get cellComponent() {
    if (this.args.cellComponent) {
      return this.args.cellComponent;
    }

    return TableHeader;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Table::Head': typeof TableHeadComponent;
    'table/head': typeof TableHeadComponent;
  }
}
