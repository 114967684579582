import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { WithBoundArgs } from '@glint/template';
import { assign, Machine } from 'xstate';

import { action } from '@ember/object';

import { useMachine } from 'ember-statecharts';

import type TableRow from '../components/table/row.ts';
import TableCell from '../components/table/td.ts';
import { matchesState } from '../utils/statecharts.ts';
import type TableBodyInfiniteComponent from './table/body-infinite.ts';
import type TableBodyComponent from './table/body.ts';
import TableFootComponent from './table/foot.ts';
import TableHeadComponent from './table/head.ts';
import TableTableComponent from './table/table.ts';

interface StateContext {
  data: any[];
}

interface StateSchema {
  states: {
    idle: {};
    reordering: {};
  };
}

type StateEvent =
  | { type: 'START'; data: any[] }
  | { type: 'ORDER'; from: number; to: number }
  | { type: 'END'; datum: any };

interface TableSignature {
  Args: {
    reorderItems?: Function;
    isReordeable?: boolean;
    rowClass?: string;
  };
  Blocks: {
    default: [
      {
        ui: {
          table: typeof TableTableComponent;
          head: WithBoundArgs<
            typeof TableHeadComponent,
            'registerHeaderCell' | 'unregisterHeaderCell'
          >;
          body: typeof TableBodyComponent;
          bodyInfinite: typeof TableBodyInfiniteComponent;
          foot: typeof TableFootComponent;
          row: WithBoundArgs<
            typeof TableRow,
            'expandedRows' | 'toggleExpand' | 'collapse'
          >;
          cell: typeof TableCell;
        };
      }
    ];
  };
}

export default class Table extends Component<TableSignature> {
  multiRowExpansion = true;

  @tracked cells: TableCell[] = [];
  @tracked expandedRows: TableRow[] = [];

  tableComponent = TableTableComponent;
  tfootComponent = TableFootComponent;
  theadComponent = TableHeadComponent;
  tdComponent = TableCell;

  statechart = useMachine<StateContext, StateSchema, StateEvent, any, {}>(
    this,
    () => ({
      machine: Machine<StateContext, StateSchema, StateEvent>({
        initial: 'idle',
        states: {
          idle: {
            on: {
              START: {
                target: 'reordering',
                cond: 'isReordeable',
                actions: assign({
                  data: (_c, { data }) => data
                })
              }
            }
          },
          reordering: {
            on: {
              ORDER: {
                actions: ['order']
              },
              END: {
                target: 'idle',
                actions: ['persistOrder']
              }
            }
          }
        }
      })
        .withContext({ data: [] })
        .withConfig({
          actions: {
            order: (
              context,
              { from, to }: Extract<StateEvent, { type: 'ORDER' }>
            ) => {
              const newData = [
                ...context.data.slice(0, from),
                ...context.data.slice(from + 1)
              ];
              newData.splice(to, 0, context.data[from]);
              context.data = [...newData];
            },
            persistOrder: (
              context,
              { datum }: Extract<StateEvent, { type: 'END' }>
            ) => {
              this.args.reorderItems?.(context.data, datum);
              context.data = [];
            }
          },
          guards: {
            isReordeable: () => {
              return this.args.isReordeable ?? false;
            }
          }
        })
    })
  );

  @matchesState('reordering') isReordering!: boolean;

  get reorderData() {
    return this.statechart.state?.context.data || [];
  }

  @action
  reorderItems(from: number, to: number) {
    this.statechart.send('ORDER', { from, to });
  }

  @action
  reorderEnd(datum: any) {
    this.statechart.send('END', { datum });
  }

  @action
  reorderStart(data: any[]) {
    this.statechart.send('START', { data });
  }

  @action
  registerHeaderCell(cellComponent: TableCell) {
    this.cells = [...this.cells, cellComponent];
  }

  @action
  unregisterHeaderCell(cellComponent: TableCell) {
    const index = this.cells.indexOf(cellComponent);
    if (index >= 0) {
      this.cells.splice(index, 1);
      this.cells = [...this.cells];
    }
  }

  @action
  collapse(row: TableRow) {
    const index = this.expandedRows.indexOf(row);
    if (index >= 0) {
      this.expandedRows.splice(index, 1);
      this.expandedRows = [...this.expandedRows];
    }
  }

  @action
  toggleExpand(row: TableRow) {
    const index = this.expandedRows.indexOf(row);
    if (index >= 0) {
      this.expandedRows.splice(index, 1);
      this.expandedRows = [...this.expandedRows];
    } else {
      if (!this.multiRowExpansion) {
        this.expandedRows = [];
      }
      this.expandedRows = [...this.expandedRows, row];
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Table: typeof Table;
  }
}
