import Component from '@glimmer/component';

export interface TableThArgs {
  registerHeaderCell: (th: TableHeader<any>) => void;
  unregisterHeaderCell: (th: TableHeader<any>) => void;
  cellClass?: string;
}

interface TableThSignature<T extends TableThArgs> {
  Args: T;
  Blocks: { default: [] };
  Element: HTMLTableCellElement;
}

export default class TableHeader<T extends TableThArgs> extends Component<
  TableThSignature<T>
> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Table::Th': typeof TableHeader;
    'table/th': typeof TableHeader;
  }
}
