import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { WithBoundArgs } from '@glint/template';
import { hash } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import pipe from 'ember-composable-helpers/helpers/pipe';
import or from 'ember-truth-helpers/helpers/or';
import ButtonContainer from './button/container.gts';
import Icon from './icon.gts';
import Loading from './loading.gts';
import Trigger from './trigger.ts';
interface ButtonSignature {
    Args: {
        blurOnClick?: boolean;
        textAlign?: string;
        onClick?: Function;
        disabled?: boolean;
        onSuccess?: Function;
        onError?: Function;
        type?: string;
        testSelector?: string;
        isBusy?: string | boolean;
        classes?: string;
    };
    Blocks: {
        default: [{
                ui: {
                    leftIcon: WithBoundArgs<typeof Icon, '_classes'>;
                    content: typeof ButtonContainer;
                    rightIcon: WithBoundArgs<typeof Icon, '_classes'>;
                };
                state: {
                    isBusy?: boolean;
                };
            }];
    };
    Element: HTMLButtonElement;
}
export type ButtonDefaultBlock = ButtonSignature['Blocks']['default'][0];
export default class Button extends Component<ButtonSignature> {
    @action
    blurOnClick(ev1: PointerEvent) {
        if (this.args.blurOnClick) {
            const el1 = ev1.currentTarget;
            if (el1) {
                (el1 as HTMLElement).blur();
            }
        }
        return ev1;
    }
    get textAlign() {
        return this.args.textAlign ?? 'text-center';
    }
    static{
        template(`
    <Trigger @onClick={{@onClick}} @disabled={{@disabled}} @onSuccess={{@onSuccess}} @onError={{@onError}} as |c|>
      <button
        type={{or @type "button"}}
        class="flex items-center  {{@classes}}"
        ...attributes
        {{on "click" (pipe this.blurOnClick c.actions.click)}}
        disabled={{c.state.isDisabled}}
        data-test-button={{@testSelector}}
      >
        {{#if (or @isBusy c.state.isBusy)}}
          <ButtonContainer class="relative {{this.textAlign}}">
            &nbsp;<Loading />
          </ButtonContainer>
        {{else if (has-block-params)}}
          {{yield (hash
            ui=(hash
              leftIcon=(component Icon _classes="mr-3")
              content=ButtonContainer
              rightIcon=(component Icon _classes="ml-3")
            )
            state=(hash
              isBusy=c.state.isBusy
            )
          )}}
        {{else}}
          <ButtonContainer class="relative {{this.textAlign}}">
            {{! @glint-ignore }}
            {{yield}}
          </ButtonContainer>
        {{/if}}
      </button>
    </Trigger>

  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
