import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import type ConfigService from '../services/config.ts';
interface IconSignature {
    Args: {
        name: string;
        w?: string;
        h?: string;
        _classes?: string;
        classes?: string;
        screenReaderText?: string;
    };
}
export default class Icon extends Component<IconSignature> {
    @service('config')
    configService: ConfigService;
    private get fillIcons() {
        return this.configService.getValue('tangram.icons.fillIcons', []);
    }
    private get fillStrokeIcons() {
        return this.configService.getValue('tangram.icons.fillStrokeIcons', []);
    }
    private get remixIcons() {
        return this.configService.getValue('tangram.icons.remixIcons', []);
    }
    get widthClass() {
        return this.args.w ?? 'w-4';
    }
    get heightClass() {
        return this.args.h ?? 'h-auto';
    }
    get _classes() {
        const classes1 = [
            this.args._classes ?? '',
            this.args.classes ?? '',
            this.widthClass,
            this.heightClass
        ];
        if (this.remixIcons.includes(this.args.name)) {
            return classes1.join(' ');
        }
        if (this.fillStrokeIcons.includes(this.args.name)) {
            classes1.push('stroke-current fill-current');
        } else if (this.fillIcons.includes(this.args.name)) {
            classes1.push('fill-current');
        } else {
            classes1.push('stroke-current');
        }
        return classes1.join(' ');
    }
    static{
        template(`
    {{svgJar @name data-test-icon=@name class=this._classes aria-hidden="true"}}
    {{#if @screenReaderText}}
      <div class="sr-only">{{@screenReaderText}}</div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
