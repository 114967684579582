import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { WithBoundArgs } from '@glint/template';

import { action } from '@ember/object';
import { later } from '@ember/runloop';

import type TableComponent from '../table.ts';
import type TableActionsCellComponent from './actions-cell.ts';
import type TableReordeableCellComponent from './reordeable-cell.ts';
import type TableCell from './td.ts';
interface TableRowSignature {
  Args: {
    expandedRows?: TableRow[];
    cells?: TableCell[];
    isExpandable?: boolean;
    isExpandedOnInit?: boolean;
    expandedClasses?: string;
    toggleExpand?: TableComponent['toggleExpand'];
    collapse?: TableComponent['collapse'];
    reorderItems?: TableComponent['reorderItems'];
    reorderStart?: Function;
    reorderEnd?: Function;
    isReordeable?: boolean;
    isDraggableOnInit?: boolean;
    useDefaultCursor?: boolean;
    useDefaultRole?: boolean;
    class?: string;
    index?: number;
    colspan?: string;
    cellClass?: string;
  };
  Blocks: {
    default: [
      {
        ui: {
          cell: WithBoundArgs<typeof TableCell, 'colspan' | 'cellClass'>;
          actionsCell: WithBoundArgs<
            typeof TableActionsCellComponent,
            'colspan' | 'isExpanded' | 'close'
          >;
          reordeableCell: WithBoundArgs<
            typeof TableReordeableCellComponent,
            'withIcon'
          >;
          expandedContent?: WithBoundArgs<
            typeof TableRow,
            'class' | 'colspan' | 'expandedRows' | 'toggleExpand' | 'collapse'
          >;
        };
        state: {
          isExpanded: boolean;
          index: number;
          expandedContent?: boolean;
        };
        actions: {
          toggleExpand: () => void;
          toggleDraggable: TableRow['toggleDraggable'];
          collapse: Function;
        };
      }
    ];
  };
  Element: HTMLTableRowElement;
}

export type TableRowYield = TableRowSignature['Blocks']['default'][0];

type CSSProperty = Exclude<
  {
    [K in keyof CSSStyleDeclaration]: CSSStyleDeclaration[K] extends string
      ? K
      : never;
  }[keyof CSSStyleDeclaration],
  number
>;

function copyAllComputedStyle(from: HTMLElement, to: HTMLElement) {
  const allComputedStyles = window.getComputedStyle(from);

  Object.keys(to.style).forEach(
    (prop: CSSProperty) => (to.style[prop] = allComputedStyles[prop])
  );

  to.style.width = `${from.clientWidth}px`;
}

function createDraggingTr(event: DragEvent) {
  const target = event.target as HTMLElement;
  const parentTable = getParentNode(target, 'TABLE');
  parentTable.classList.add('dragging-table');
  const table = document.createElement('table');
  copyAllComputedStyle(parentTable, table);
  table.classList.add('drag-table');
  table.dataset.isDragRow = 'true';

  const tbody = document.createElement('tbody');
  const tr = document.createElement('tr');
  copyAllComputedStyle(target, tr);
  tr.classList.add('drag-tr');

  [...target.children].forEach((child: HTMLElement) => {
    const td = child.cloneNode(true) as HTMLElement;
    td.classList.forEach(cls => td.classList.remove(cls));
    copyAllComputedStyle(child, td);
    tr.appendChild(td);
  });
  tbody.appendChild(tr);
  table.appendChild(tbody);

  table.style.height = `${target.clientHeight}px`;
  const offset = event.offsetY;
  table.dataset.draggingOffset = `${offset}`;
  table.style.left = `${event.pageX - event.offsetX}px`;
  table.style.top = `${event.pageY - event.offsetY}px`;
  return table;
}

function getParentNode(target: HTMLElement, tagName: string) {
  let parentTable = target;
  while (parentTable.tagName !== tagName.toUpperCase()) {
    parentTable = parentTable.parentElement!;
  }
  return parentTable;
}

function createGhostImage() {
  const image = document.createElement('div');
  image.dataset.isDragImage = 'true';
  return image;
}

function updateGhostImagePos(pageY: number) {
  const table = document.querySelector('[data-is-drag-row]') as HTMLElement;
  if (table) {
    const offset = parseInt(table.dataset.draggingOffset!, 10);
    const pos = pageY - offset;
    const tbody = document.querySelector('.dragging-table tbody');
    if (tbody) {
      const { y, height } = tbody.getBoundingClientRect();
      if (pos >= y && pos < y + height - table.clientHeight) {
        table.style.top = `${pageY - offset}px`;
      }
    }
  }
}

export default class TableRow extends Component<TableRowSignature> {
  @tracked isRowDraggable?: boolean = this.args.isDraggableOnInit;

  get isExpanded() {
    return this.args.expandedRows && this.args.expandedRows.indexOf(this) > -1;
  }

  get cellsCount() {
    return this.args.cells && this.args.cells.length;
  }

  get cursor() {
    if (this.args.useDefaultCursor) {
      return '';
    }
    if (this.args.isReordeable) {
      return 'cursor-grab';
    }
    if (this.args.isExpandable) {
      return 'cursor-pointer';
    }
    return '';
  }

  get role() {
    if (this.args.useDefaultRole) {
      return 'row';
    }
    return 'button';
  }

  get draggable(): boolean {
    return this.isRowDraggable ?? this.args.isReordeable ?? false;
  }

  @action
  expandOnInit() {
    if (this.args.isExpandedOnInit) {
      this.args.toggleExpand(this);
    }
  }

  @action
  toggleExpandIfExpandable(): void {
    if (this.args.isExpandable) {
      this.args.toggleExpand(this);
    }
  }

  @action
  toggleDraggable(draggable: boolean) {
    this.isRowDraggable = draggable;
  }

  @action
  dragStart(index: number, event: DragEvent) {
    const target = event.target as HTMLElement | null;
    if (target) {
      if (event.dataTransfer) {
        const table = createDraggingTr(event);
        table.dataset.draggingIndex = `${index}`;
        table.addEventListener('dragover', this.ghostDragOver);
        later(() => {
          // delaying it, otherwise drag event is cancelled
          document.body.appendChild(table);
        }, 1);

        const image = createGhostImage();
        document.body.appendChild(image);
        event.dataTransfer.setDragImage(image, event.offsetX, event.offsetY);
        event.dataTransfer.effectAllowed = 'move';

        target.classList.add('is-dragging');
        this.args.reorderStart?.();
      }
    }
  }

  @action
  dragEnter(index: number) {
    if (this.args.isReordeable) {
      this.switchRows(index);
    }
  }

  private switchRows(index: number | undefined) {
    const draggingObject = document.querySelector(
      '[data-is-drag-row]'
    ) as HTMLElement;
    if (draggingObject) {
      const draggingIndex = parseInt(draggingObject.dataset.draggingIndex!, 10);
      if (draggingIndex !== index) {
        this.args.reorderItems?.(draggingIndex, index);
        draggingObject.dataset.draggingIndex = `${index}`;
      }
    }
  }

  @action
  dragOver(event: DragEvent) {
    // allow dropping, otherwise the drag image comes back to it's original position
    event.preventDefault();
    if (this.args.isReordeable) {
      updateGhostImagePos(event.pageY);
    }
  }

  @action
  ghostDragOver(event: DragEvent) {
    this.dragOver(event);
    const rows = document.querySelectorAll(
      '.dragging-table tbody tr[draggable]'
    );
    rows.forEach((row, index) => {
      const { y, height } = row.getBoundingClientRect();
      if (y <= event.pageY && y + height > event.pageY) {
        this.switchRows(index);
      }
    });
  }

  @action
  dragEnd(event: DragEvent) {
    const el = event.target as HTMLElement;
    el.classList.remove('is-dragging');
    el.classList.add('has-dragged');
    later(() => {
      el?.classList.remove('has-dragged');
    }, 500);
    document.querySelector('[data-is-drag-image]')?.remove();
    document.querySelector('[data-is-drag-row]')?.remove();
    document
      .querySelector('.dragging-table')
      ?.classList.remove('dragging-table');
    this.args.reorderEnd?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Table::Row': typeof TableRow;
    'table/row': typeof TableRow;
  }
}
