import { action } from '@ember/object';

import type {
  ExtractFormProps,
  ExtractFormValue,
  FormContext
} from './-control-base.ts';
import FormBaseComponent from './-control-base.ts';
import type { FormTheme } from './theme.ts';

type InputProperty = string | number | null;

interface Signature<
  Context extends FormContext,
  Property extends ExtractFormProps<Context, InputProperty>,
  Value
> {
  Args: {
    f: Context;
    property: Property;
    value?: Value;
    label?: string;
    testSelector?: string;
    hint?: string;
    wrapperClasses?: string;
    formFieldWrapperClasses?: string;
    theme?: FormTheme;

    type?: 'number';
    icon?: string;
    iconSize?: string;
    displayIconLeft?: boolean;
    displayIconRight?: boolean;
    buttonClass?: string;
    buttonType?: string;
    isDisabled?: boolean;
    inline?: boolean;
    withLinkableLabel?: boolean;
    route?: string;
    models?: any[];
    linkableLabelTooltip?: string;
    wrapperWidth?: string;
    placeholder?: string;
    clearable?: boolean;
    autoGrow?: boolean;

    onLinkableLabelClick?: Function;
    onClickIcon?: Function;
    onValueChanged?: (value: string | number | null) => void;
  };
  Blocks: {
    default: [];
    afterLabel: [];
  };
  Element: HTMLButtonElement;
}

function transform(value: string, type: 'number') {
  switch (type) {
    case 'number':
      if (value === '') {
        return null;
      }
      return Number(value);
  }
}

export default class FormInput<
  Context extends FormContext,
  Property extends ExtractFormProps<Context, InputProperty>,
  Value extends ExtractFormValue<Context, Property>
> extends FormBaseComponent<
  Signature<Context, Property, Value>,
  InputProperty
> {
  @action
  updateWithValue(inputEvent: { target: HTMLInputElement }) {
    const {
      target: { value }
    } = inputEvent;

    if (this.args.type) {
      return this.change(transform(value, this.args.type));
    }

    return this.change(value);
  }

  @action
  clearValue() {
    return this.change('');
  }

  private change(value: string | number | null) {
    this.onChange(value);
    this.args.onValueChanged?.(value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Form::Input': typeof FormInput;
  }
}
