import { template } from "@ember/template-compiler";
import type { ButtonDefaultBlock } from '../../button.gts';
import ButtonTopBase from './base.gts';
interface ButtonTopPrimarySignature {
    Args: {
        disabled?: boolean;
        isBusy?: string;
        type?: string;
        onClick?: Function;
        onSuccess?: Function;
        onError?: Function;
        testSelector?: string;
        narrowStyle?: boolean;
        contentClasses?: string;
        classes?: string;
        text?: string;
        small?: boolean;
        borderRadius?: 'normal' | 'small';
    };
    Blocks: {
        default: [ButtonDefaultBlock];
    };
    Element: HTMLButtonElement;
}
const ButtonTopPrimaryComponent: TemplateOnlyComponent<ButtonTopPrimarySignature> = template(`
  <ButtonTopBase
    @disabled={{@disabled}}
    @onClick={{@onClick}}
    @onSuccess={{@onSuccess}}
    @onError={{@onError}}
    @testSelector={{@testSelector}}
    @narrowStyle={{@narrowStyle}}
    @small={{@small}}
    @type={{@type}}
    @borderRadius={{@borderRadius}}
    class="cta-button--top-primary {{@classes}}"
  ...attributes
    as |c|>
    <c.ui.content>
      {{#if (has-block)}}
        {{yield c}}
      {{else}}
        {{@text}}
      {{/if}}
    </c.ui.content>
  </ButtonTopBase>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ButtonTopPrimaryComponent;
