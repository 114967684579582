import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import Button from './button.gts';
import Icon from './icon.gts';
interface BadgeSignature {
    Args: {
        label?: string;
        bgColor?: string;
        textColor?: string;
        display?: string;
        icon?: string;
        isSmall?: boolean;
        isOnlyIcon?: boolean;
        onRemove?: Function;
    };
    Element: HTMLDivElement;
}
export default class BadgeComponent extends Component<BadgeSignature> {
    get display(): string {
        return this.args.display ?? 'inline-flex';
    }
    get bgColor(): string {
        return this.args.bgColor ?? 'bg-gray-100';
    }
    get textColor(): string {
        return this.args.textColor ?? 'text-gray-500';
    }
    get leftPadding(): string {
        if (this.args.isOnlyIcon) {
            return 'pl-0';
        }
        if (this.args.isSmall) {
            return 'pl-2';
        }
        return this.args.icon ? 'pl-2' : 'pl-3';
    }
    get rightPadding(): string {
        if (this.args.isOnlyIcon) {
            return 'pl-0';
        }
        if (this.args.isSmall) {
            return 'pr-2';
        }
        return this.args.onRemove || !this.args.label ? 'pr-2' : 'pr-3';
    }
    static{
        template(`
    <div class="items-center justify-around font-medium rounded whitespace-no-wrap
      {{this.display}} {{this.bgColor}} {{this.textColor}} {{this.leftPadding}} {{this.rightPadding}}
      {{if @isSmall "h-5 text-xs gap-1" "h-6 text-sm gap-2"~}}
    " ...attributes>
      {{#if @icon}}
        <Icon @name={{@icon}} @w="{{if @isSmall "w-2" "w-3"}}" />
      {{/if}}

      {{@label}}

      {{#if @onRemove}}
        <Button @onClick={{@onRemove}} data-test-remove-badge as |c|>
          <c.ui.rightIcon @name="x" @w="w-2" @_classes="mt-px" />
        </Button>
      {{/if}}
    </div>

  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        Badge: typeof BadgeComponent;
        badge: typeof BadgeComponent;
    }
}
