import type { EventObject } from 'xstate';
import { assign, Machine } from 'xstate';

import type ErrorsService from '../../services/-errors-base.ts';
import type NotificationsService from '../../services/notifications.ts';
import { getAllUserMessagesOrDefault } from '../../utils/errors.ts';

type InvokedEvent = EventObject & { data: any };

type SimpleLoadRecordsContext = {
  records: any[];
  notifications?: NotificationsService;
  errors?: ErrorsService;
};

export const SimpleLoadRecordsMachine = Machine(
  {
    initial: 'setup',
    context: { records: [] },
    on: { SEARCH: '#loading' },
    states: {
      setup: {
        always: [
          { target: '#loading', cond: 'searchOnStartup' },
          { target: 'idle' }
        ]
      },
      idle: {},
      loading: {
        id: 'loading',
        initial: 'debounced',
        states: {
          debounced: {
            after: {
              DEBOUNCE_DELAY: 'searching'
            }
          },
          searching: {
            invoke: {
              src: 'search',
              onDone: {
                target: '#success',
                actions: [
                  assign({
                    records: (_, event: InvokedEvent) => {
                      return event.data;
                    }
                  })
                ]
              },
              onError: '#error'
            }
          }
        }
      },
      success: {
        id: 'success',
        entry: 'onSuccess'
      },
      error: {
        id: 'error',
        entry: 'onError'
      }
    }
  },
  {
    guards: {
      searchOnStartup: () => false
    },
    actions: {
      onSuccess: () => {},
      onError: (context: SimpleLoadRecordsContext, event: InvokedEvent) => {
        context.errors?.log(event.data);
        context.notifications?.error({
          message: getAllUserMessagesOrDefault(event.data)[0]
        });
      }
    },
    delays: {
      DEBOUNCE_DELAY: 0
    }
  }
);
