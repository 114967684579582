import type { BrandingOptions } from '../modifiers/base-branding.ts';

export const LOOK_AND_FEEL_FALLBACK: Partial<LookAndFeel> = {
  time_selection_bg_color: 'accent_color',
  seats_selected_fill_color: 'accent_color',
  seats_selected_stroke_color: 'accent_color',
  seats_in_cart_fill_color: 'card_bg_color',
  seats_in_cart_stroke_color: 'card_bg_color'
};

export const LOOK_AND_FEEL_DEFAULT: Partial<LookAndFeel> = {
  background_repeat: 'no-repeat',
  text_color: '#444444',
  links_color: '#cb1673',
  accent_color: '#D8C613',
  container_bg_color: '#fafafa',
  card_fg_color: 'white',
  card_bg_color: '#cb1673',
  card_cta_bg_color: '#cb1673',
  external_bg_color: '#ededed',
  sub_header_bg_color: 'rgba(white, 0.8)',
  navbar_fg_color: 'white',
  navbar_bg_color: '#cb1673',
  cart_badge_fg_color: 'white',
  cart_badge_bg_color: 'red',
  cart_preview_fg_color: '#444',
  cart_preview_bg_color: 'white',
  cart_buttons_fg_color: 'white',
  cart_buttons_bg_color: '#cb1673',
  cart_discount_fg_color: 'black',
  cart_discount_bg_color: '#ecf58e',
  sidebar_fg_color: '#444',
  sidebar_bg_color: '#ddd',
  cta_bg_color: '#D8C613',
  time_selection_bg_color: 'white',
  seats_blocked_fill_color: '#ccc',
  seats_blocked_stroke_color: '#ccc',
  seats_selected_fill_color: '#D8C613',
  seats_selected_stroke_color: '#D8C613',
  seats_in_cart_fill_color: '#cb1673',
  seats_in_cart_stroke_color: '#cb1673',
  card_warning_bg_color: '#ffeb3b'
};

export const MODIFIER_OPTIONS: {
  [x: string]: (active?: boolean) => BrandingOptions;
} = {
  CARD_TEXT_COLOR: () => ({
    positional: ['color', 'card_fg_color'],
    named: {}
  }),
  CARD_BUTTON_TEXT_COLOR: active =>
    active
      ? {
          positional: ['color', 'time_selection_bg_color'],
          named: { contrastedColor: true }
        }
      : {
          positional: ['color', 'card_fg_color'],
          named: {}
        },
  CARD_BUTTON_BORDER_COLOR: active =>
    active
      ? {
          positional: ['borderColor', 'time_selection_bg_color'],
          named: {}
        }
      : {
          positional: ['borderColor', 'card_bg_color'],
          named: {
            mix: { color1: 'card_bg_color', color2: 'white', weight: 80 },
            onMouseHover: {
              mix: { color1: 'card_bg_color', color2: 'white', weight: 90 }
            }
          }
        },
  CARD_BUTTON_BACKGROUND_COLOR: active =>
    active
      ? {
          positional: ['backgroundColor', 'time_selection_bg_color'],
          named: {}
        }
      : {
          positional: ['backgroundColor', 'card_bg_color'],
          named: {
            mix: { color1: 'card_bg_color', color2: 'white', weight: 80 },
            onMouseHover: {
              mix: { color1: 'card_bg_color', color2: 'white', weight: 90 }
            }
          }
        },
  CARD_BACKGROUND_COLOR: () => ({
    positional: ['backgroundColor', 'card_bg_color'],
    named: {}
  }),
  CARD_SECONDARY_BACKGROUND_COLOR: (active: boolean) => ({
    positional: ['backgroundColor', 'card_secondary_bg_color'],
    named: {
      enabled: active
    }
  }),
  CARD_SECONDARY_TEXT_COLOR: () => ({
    positional: ['color', 'card_secondary_fg_color'],
    named: {}
  })
};

/**
 * `look_and_feel` setting from
 * https://github.com/ticketsolve/ticketsolve/blob/d04cb456c010394990ea91578aac88512ffa9ae2/app/modules/ticketbooth/lib/ticketbooth/hooks.rb#L56
 */
export type LookAndFeel = {
  text_color: string;
  links_color: string;
  accent_color: string;
  container_bg_color: string;
  card_fg_color: string;
  card_bg_color: string;
  card_cta_bg_color: string;
  card_warning_bg_color: string;
  external_bg_color: string;

  font_url_1: string;
  font_url_2: string;

  sub_header_bg_color: string;
  navbar_fg_color: string;
  navbar_bg_color: string;
  cart_badge_fg_color: string;
  cart_badge_bg_color: string;
  cart_preview_fg_color: string;
  cart_preview_bg_color: string;
  cart_buttons_fg_color: string;
  cart_buttons_bg_color: string;
  cart_discount_fg_color: string;
  cart_discount_bg_color: string;
  sidebar_fg_color: string;
  sidebar_bg_color: string;
  cta_bg_color: string;
  time_selection_bg_color: string;

  seats_blocked_fill_color: string;
  seats_blocked_stroke_color: string;
  seats_selected_fill_color: string;
  seats_selected_stroke_color: string;
  seats_in_cart_fill_color: string;
  seats_in_cart_stroke_color: string;

  background_image: string;
  background_repeat: string;
  background_size: string;
  background_attachment: string;
  header_background_image: string;

  show_category: boolean;
  show_venues: boolean;
  show_company: boolean;
  show_dates: boolean;

  mail_bg_color: string;
  mail_container_bg_color: string;
  mail_text_color: string;

  mail_cta_bg_color: string;
  mail_cta_fg_color: string;

  mail_footer_bg_color: string;
  mail_footer_logo_url: string;

  mail_header_bg_color: string;
  mail_header_logo_url: string;

  mail_important_bg_color: string;
  mail_important_fg_color: string;

  mail_link_color: string;

  mail_sub_container_border_color: string;
  mail_sub_container_bg_color: string;
  mail_sub_container_fg_color: string;

  mail_font_fallback: string;
  mail_font_name: string;
  mail_font_url: string;

  reserved_order_badge_bg_color: string;
  reserved_order_badge_fg_color: string;

  card_secondary_fg_color: string;
  card_secondary_bg_color: string;

  snippet_fg_color: string;
  snippet_bg_color: string;
  neutral_button_fg_color: string;
  neutral_button_bg_color: string;
  secondary_card_snippet_fg_color: string;
  secondary_card_snippet_bg_color: string;
};
